<template>
  <div class="container">
    <div id="head">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="notice kefan">
            <!-- <div class="top">
              <h4>通告栏</h4>
              <p class="alist" style="position: relative; left: 70%">more>></p>
            </div>
            <el-divider></el-divider>
            <ul>
              <li v-for="i in 4" :key="i">
                <p class="alist">[2022-12-22]考试通知</p>
              </li>
            </ul> -->
            <div>
              <h4>案例分类</h4>
              <el-divider></el-divider>
            </div>
            <div id="circle" style="width: 95%; height: 176px"></div>
          </div>
        </el-col>
        <el-col :span="15">
          <div class="tj kefan">
            <div class="top">
              <h4>精选案例</h4>
              <p
                class="alist"
                style="position: relative; left: 80%"
                @click="toanlimore"
              >
                more>>
              </p>
            </div>
            <el-divider></el-divider>
            <ul>
              <li v-for="item in goodanli" :key="item.id">
                <p class="alist" @click="toanli(item.id)">
                  [{{ item.bumen }}]{{ item.title }}
                </p>
                <div style="position: absolute; right: 5rem; width: 10%">
                  <p style="color: #9f9f9f; fontsize: 14px">
                    提供者:{{ item.author }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tools">
      <h3>常用工具</h3>
      <el-divider></el-divider>
      <el-row :gutter="20">
        <el-col :span="23">
          <div class="kefan">
            <el-row :gutter="60">
              <el-col :span="8" v-for="item in toolimg" :key="item.id">
                <div
                  class="card"
                  @click="go(item.href)"
                  v-loading.fullscreen.lock="fullscreenLoading"
                >
                  <div class="c_title">
                    <el-avatar
                      :src="item.img"
                      :size="60"
                      :fit="'cover'"
                    ></el-avatar>
                    <p>{{ item.text }}</p>
                    <div class="info">
                      <p>{{ item.info }}</p>
                    </div>
                    <div class="round">
                      <div class="container">
                        <i class="el-icon-right" style="fontsize: 30px"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="download">
      <h3>小程序码下载</h3>
      <el-divider></el-divider>
      <div class="img">
        <el-row :gutter="20">
          <el-col :span="23">
            <div class="imgdata kefan">
              <el-row :gutter="40">
                <el-col
                  style="text-align: center"
                  v-for="(item, index) in logo"
                  :key="index"
                  :span="4"
                  :push="2"
                >
                  <el-image
                    style="width: 150px; height: 150px"
                    :src="item.url"
                    :fit="'contain'"
                  ></el-image>
                  <p>{{ item.name }}</p>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import * as echarts from "echarts";
export default {
  data() {
    return {
      logo: [],
      anlinums: [],
      yuanquy: [],
      yuanqux: [],
      bgimg: [],
      goodanli: [],
      fullscreenLoading: false,
      toolimg: [
        {
          id: 1,
          img: this.$img + "1.ico",
          text: "免费pdf工具",
          info: "完全免费的处理PDF文件的在线服务。可以合并PDF，分割PDF，压缩PDF等",
          href: "https://www.ilovepdf.com/zh-cn",
        },
        {
          id: 2,
          img: this.$img + "2.jpg",
          text: "日期计算器",
          info: "在线日期计算器、日期推算、日期相差天数，准确计算两个日期间隔天数",
          href: "https://www.jishirili.com/riqicha.asp",
        },
        {
          id: 3,
          img: this.$img + "3.png",
          text: "智能生成图表(免费)",
          info: "在线免费制作图表,操作简单,功能强大",
          href: "https://chartcube.alipay.com/",
        },
        {
          id: 4,
          img: this.$img + "4.ico",
          text: "视频去水印",
          info: "免费在线短视频解析,支持解析的视频站点非常全面",
          href: "https://parse.bqrdh.com/smart/",
        },
        {
          id: 5,
          img: this.$img + "5.jpg",
          text: "文件批量重命名",
          info: "无需下载安装，直接在线完成文件批量重命名",
          href: "https://webrename.cn/",
        },
        {
          id: 6,
          img: this.$img + "6.ico",
          text: "白描/图片转文字",
          info: "准确中文ocr文字识别、可以表格、pdf识别、图片转Excel，拍照翻译",
          href: "https://web.baimiaoapp.com/",
        },
      ],
    };
  },
  methods: {
    postanlinums() {
      axios({
        method: "get",
        url: this.$host + this.$api.anlinums,
      })
        .then((res) => {
          // console.log(res.data)
          this.anlinums = res.data.anli;
          this.yuanquy = res.data.yuanquy;
          this.yuanqux = res.data.yuanqux;
        })
        .then(() => {
          var chartDom = document.getElementById("circle");
          window.onresize = function () {
            myChart.resize();
          };
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            tooltip: {
              trigger: "item",
            },
            series: [
              {
                name: "案例分类统计",
                type: "pie",
                radius: "50%",
                data: this.anlinums,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };
          option && myChart.setOption(option);
        });
    },
    getxcximg() {
      axios({
        method: "get",
        url: this.$host + this.$api.xcximg,
      }).then((res) => {
        this.logo = res.data;
      });
    },
    getgoodanli() {
      axios({
        method: "get",
        url: this.$host + this.$api.goodanli,
      }).then((res) => {
        this.goodanli = res.data;
      });
    },
    toanli(id) {
      this.$router.push({ path: "/article", query: { id: id } });
    },
    go(url) {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
        window.open(url);
      }, 2000);
    },
    toanlimore() {
      this.$router.push({ path: "/anlimore" });
    },
  },
  mounted() {
    this.getxcximg();
    this.getgoodanli();
    this.postanlinums()
  },
};
</script>
<style scoped>
.notice .el-divider--horizontal {
  width: 80px;
  margin: 5px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.card {
  margin-bottom: 3rem;
}
.c_title {
  text-align: center;
}
.c_title:hover {
  cursor: pointer;
}
.c_title:hover .container {
  cursor: pointer;
  background: #ff7400;
  color: #000;
  transition: all 0.5s ease;
}

.card .info {
  color: #9f9f9f;
  font-size: 12px;
  height: 60px;
  overflow: hidden;
  /* width: 50%; */
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .info p {
  width: 50%;
  margin-top: 1em;
  -webkit-line-clamp: 3;
}
.round {
  display: flex;
  align-items: center;
  justify-content: center;
}
.round .container {
  margin-top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.round .container .el-icon-right {
  color: #fff;
}
.download .el-divider--horizontal {
  width: 120px;
  margin: 7px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.tools .el-divider--horizontal {
  width: 120px;
  margin: 7px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.tj .el-divider--horizontal {
  width: 80px;
  margin: 5px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.dsj .el-divider--horizontal {
  width: 100px;
  margin: 10px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.dsj .kfdsj .el-divider--horizontal {
  width: 70px;
  margin: 5px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.notice li,
.tj li {
  padding: 8px 0;
}
.dsj li {
  padding: 12px 0;
}
.tj li {
  display: flex;
  align-items: center;
}
.imgdata p {
  text-align: center;
}
.notice{
  padding-bottom: 0px !important;
}
.notice,
.tj {
  min-height: 180px;
}
.top {
  display: flex;
  align-items: center;
}
.alist {
  color: #9f9f9f;
}
.alist:hover {
  color: #4fc08d;
  cursor: pointer;
}

/* 要删掉的 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/* 删掉的部分 */
</style>